import { KeyboardArrowRight } from '@mui/icons-material';
import { useState } from 'react';
import ScheduleSelectionModal from './ScheduleSelectionModal';
import { convertTo12HourFormat } from './utils';
import { getStoreType } from 'src/utils/getStoreType';
import { STORE_CUSTOMIZATION } from 'src/utils/constants';
import { useSSRSelector } from 'src/redux';
import { getThemeColor } from 'src/utils/getThemeColor';

const ProductScheduler = ({
  schedualerConstraints,
  showSchedulerError,
  scheduleInfo,
  setScheduleInfo,
  customClasses = '',
}) => {
  const themeColor = getThemeColor();
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  const storeData = useSSRSelector((state) => state.storeReducer.store);
  const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;

  function getScheduleInfoStr() {
    const { date = null, time = null } = scheduleInfo || {};
    switch (true) {
      case !!date && !!time:
        return `${date}, ${convertTo12HourFormat(time?.from)} - ${convertTo12HourFormat(time?.to)}`;
      case !!date:
        return date;
      case !!time:
        return `${convertTo12HourFormat(time?.from)} - ${convertTo12HourFormat(time?.to)}`;
      default:
        return '';
    }
  }

  function getLabel() {
    return !scheduleInfo?.date ? (
      <>
        Schedule a booking
        <span
          className="tw-text-[12px]/[17px] tw-font-bold"
          style={{ color: themeColor }}
        >
          NEW
        </span>
      </>
    ) : (
      'Manage your booking'
    );
  }

  return (
    schedualerConstraints &&
    !isB2bStore && (
      <>
        <div
          className={`tw-flex tw-flex-col tw-gap-[10px] tw-px-[16px] tw-text-[14px]/[17px] md:tw-max-w-[500px] md:tw-px-0 md:tw-pt-[32px] ${customClasses}`}
        >
          <label className="tw-flex tw-gap-[8px] tw-text-[#414141]">{getLabel()}</label>
          <div
            className="tw-flex tw-cursor-pointer  tw-items-center tw-border tw-border-solid tw-border-[#D5D5D5] tw-px-[16px] tw-py-[12px] tw-font-normal"
            onClick={() => setShowSelectionModal(true)}
          >
            <input
              value={getScheduleInfoStr()}
              placeholder={`Select a date ${schedualerConstraints?.ranges?.length ? '& time' : ''}`}
              className="tw-h-full tw-flex-1 tw-cursor-pointer tw-border-none tw-bg-transparent tw-text-[14px]/[17px] tw-caret-transparent placeholder:tw-text-[#999999]"
              readOnly
            />
            <KeyboardArrowRight />
          </div>
          {showSchedulerError && (
            <p className="tw-m-0 tw-text-[14px]/[17px] tw-text-[#BC241D]">
              Please select a date
              {schedualerConstraints?.ranges?.length ? ' & time' : ''} to continue
            </p>
          )}
        </div>
        {showSelectionModal && (
          <ScheduleSelectionModal
            scheduleInfo={scheduleInfo}
            setScheduleInfo={setScheduleInfo}
            onClose={() => setShowSelectionModal(false)}
            schedualerConstraints={schedualerConstraints}
          />
        )}
      </>
    )
  );
};

export default ProductScheduler;
